import Vue from 'vue'
import Vuex from 'vuex'
import { ACCESS_TOKEN,GO_BACK_URL, USER_NAME,USER_INFO,USER_MULTI_DEPART, USER_DEPARTS, USER_ID } from "@/store/mutation-types"

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    token: '',
    goBackUrl:'',
    username: '',
    realname: '',
    avatar: '',
    multi_depart: 0,
    departs: [],
    userInfo: {},
    matchList: [],
    matchDetail: {},
    matchActiveTab: {
      detail: { name: '1', type: 'img' },
      join: { name: '1', type: 'form' },
      works: { name: '1', type: 'upload' },
      result: { name: '1', type: 'result' },
    },
  },
  mutations: {
    SET_TOKEN: (state, token) => {
      state.token = token;
    },
    SET_GO_BACK_URL: (state,url) => {
      state.goBackUrl = url;
    },
    SET_NAME: (state, { username, realname }) => {
      state.username = username;
      state.realname = realname;
    },
    SET_AVATAR: (state, avatar) => {
      state.avatar = avatar;
    },
    SET_MULTI_DEPART: (state, multiDepart) => {
      state.multi_depart = multiDepart;
    },
    SET_DEPARTS: (state, departs) => {
      state.departs = departs;
    },
    SET_INFO: (state, info) => {
      state.userInfo = info;
    },
    // 赛事
    SET_MATCH_LIST: (state, matchList) => {
      state.matchList = matchList;
    },
    SET_MATCH_DETAIL: (state, matchDetail) => {
      state.matchDetail = matchDetail;
    },
    CHANGE_MATCH_ACTIVE_TAB: (sate, matchActiveTab) => {
      state.matchActiveTab = matchActiveTab;
    },
  },
  actions: { 
    LoginUser({commit}, data){
      let expire = 7 * 24 * 60 * 60 * 1000
      let token = data.token;
      let backUrl = data.goBackUrl;
      Vue.ls.set(ACCESS_TOKEN, token, expire)
      Vue.ls.set(GO_BACK_URL, backUrl, expire)
      commit('SET_TOKEN', token);
      commit('SET_GO_BACK_URL', backUrl);
    },
    ChangeNickname({ commit, state }, nickname) {
      let expire = 7 * 24 * 60 * 60 * 1000
      const userInfo = state.userInfo;
      userInfo.realname = nickname;
      Vue.ls.set(USER_NAME, nickname, expire)
      Vue.ls.set(USER_INFO, userInfo, expire)
      commit('SET_INFO', userInfo);
      commit('SET_NAME', {
        username: userInfo.username,
        realname: userInfo.realname,
      });
    },
    // 登出
    LogoutUser({ commit, state }) {
      commit('SET_TOKEN', '');
      commit('SET_GO_BACK_URL', '');

      Vue.ls.remove(ACCESS_TOKEN)
      Vue.ls.remove(GO_BACK_URL)
    },
    /**
    赛事列表
     */
    SetMatchList({ commit, state }, data) {
      const { matchList } = data;
      commit('SET_MATCH_LIST', matchList);
    },
    /**
    赛事详情
     */
    SetMatchDetail({ commit, state }, data) {
      const { matchDetail } = data;
      commit('SET_MATCH_DETAIL', matchDetail);
    }
  },
  modules: {},
});
