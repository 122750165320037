<!--
 * @Author: your name
 * @Date: 2021-01-26 21:49:24
 * @LastEditTime: 2021-01-27 17:25:30
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: /portal/src/App.vue
-->
<template>
  <div id="app" class="app_root-container">
    <!-- <router-view name="header"></router-view> -->
    <router-view name="course"></router-view> 
     <!-- <div v-if="hasHeader" class="main-container">
      <router-view />
    </div> -->
    <Footer></Footer>
  </div>
</template>

<script>
import HeaderNav from '@/components/HeaderNav.vue';
import Footer from '@/components/Footer.vue';
export default {
  data() {
    return {
      activeIndex: '1',
      hasHeader: true
    };
  },
  components: {
    HeaderNav,
    Footer,
  },
  created(){
  },
  methods: {
  },
};
</script>
<style lang="scss" scoped>
.app_root-container {
  display: flex;
  flex-direction: column;
  height: 100%;
  .main-container {
    height: calc(100% - 90px);
    overflow: hidden;
    overflow-y: auto;
  }
}
</style>
