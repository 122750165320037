<template>
  <div class="global_navbar-wrapper">
    <div class="container">
      <div class="leftpad">
        <div class="logo">
          <a href="/"><img src="@/assets/image/logo.png" alt=""></a>
        </div>
        <div class="navbar">
          <el-menu
            :default-active="activeIndex"
            mode="horizontal"
            :router="true"
          >
            <el-menu-item index="/">首页</el-menu-item>
            <el-menu-item index="/lab">实验室</el-menu-item>
            <el-menu-item index="/curriculum">课程</el-menu-item>
            <el-menu-item index="/teachers">名师团</el-menu-item>
            <el-menu-item index="/campus">校园</el-menu-item>
            <el-menu-item index="/community">社区</el-menu-item>
            <el-menu-item index="/match">赛事</el-menu-item>
          </el-menu>
        </div>
      </div>
      <div class="sidepad">
        <el-menu class="el-menu-demo" mode="horizontal" v-if="isLogin">
          <el-submenu index="">
            <template slot="title"><img style="width: 30px;margin-right: 10px;" :src="userInfo.avatar"/>{{userInfo.realname}}</template>
            <el-menu-item @click="toUserinfo">个人中心</el-menu-item>
            <!-- <el-menu-item index="/user/favo">我的收藏</el-menu-item> -->
            <!-- <el-menu-item index="/">立即创作</el-menu-item> -->
            <el-menu-item @click="logoutConfirm">退出</el-menu-item>
          </el-submenu>
        </el-menu>
        <ul class="ul" v-else>
          <li class="li" @click="toLogin">登录</li>
          <!-- <li class="li" @click="toReg">注册</li> -->
        </ul>
      </div>
      <Login :dialogFormVisible.sync="showLoginModal"></Login>
    </div>
  </div>
</template>

<script>
import Login from '@/components/LoginForm';
import { getUserInfo } from '@/utils/auth';
import { logout } from '@/api/user';
import { MessageBox } from 'element-ui';

export default {
  name: 'HeaderNav',
  components: {
    Login,
  },
  data() {
    return {
      activeIndex: this.$route.meta.menuIndex,
      showLoginModal: false,
    };
  },
  watch: {
    $route(newRoute) {
      this.activeIndex = newRoute.meta.menuIndex;
    },
  },
  computed: {
    isLogin: function() {
      let userInfo = getUserInfo();
      return !!userInfo;
    },
    userInfo: function() {
      return getUserInfo();
    },
  },
  methods: {
    logoutConfirm: function() {
      let that = this;
      MessageBox({
        type: 'warning',
        title: '系统提示',
        message: '您确定要退出登录吗',
      }).then(() => {
        that.$store.dispatch('LogoutUser');
        window.location.reload();
      });
    },
    toUserinfo() {
      this.$router.push('/userinfo');
    },
    toLogin() {
      this.$router.push('/login');
    },
    toReg() {
      this.$router.push({
        path: '/login',
        query: {
          type: 'reg',
        },
      });
    },
  },
};
</script>

<style lang="scss" scoped>
@import '~@/assets/css/views/global_navbar.scss';
</style>
