import Vue from 'vue'
import Cookies from 'js-cookie';
import { ACCESS_TOKEN,USER_INFO,USER_MULTI_DEPART, USER_DEPARTS } from "@/store/mutation-types"

const TokenKey = 'Token';

export function getToken() {
  return Cookies.get(TokenKey);
}

export function setToken(token) {
  return Cookies.set(TokenKey, token);
}

export function removeToken() {
  return Cookies.remove(TokenKey);
}

export function getAccessToken() {
  return Vue.ls.get(ACCESS_TOKEN)
}

export function getUserInfo() {
  return Vue.ls.get(USER_INFO)
}

export function getUserDepartInfo() {
  return { 
    multi_depart: Vue.ls.get(USER_MULTI_DEPART), 
    departs: Vue.ls.get(USER_DEPARTS), 
  }
}
