<!--
 * @Author: your name
 * @Date: 2021-01-27 17:13:54
 * @LastEditTime: 2021-01-28 01:14:17
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: /portal/src/components/Footer.vue
-->
<template>
  <div class="footer">
    <div class="inner-wrapper">
      <p style="text-align: center;width: 100%;">
        <a style="color: #666;" href="http://beian.miit.gov.cn/state/outPortal/loginPortal.action" class="record" target="_blank">渝ICP备2021009025号-1</a> 联系我们：team@eqibian.com</p>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Footer',
};
</script>

<style lang="scss" scoped>
.footer {
  flex: 0 0 30px;
  border-top: 1px solid #eee;
  color: #666;
  background: #fff;
  .inner-wrapper {
    width: 80%;
    height: 100%;
    margin: 0 auto;
    display: flex;
    align-items: center;
    font-size: 12px;
  }
}
</style>
