/*
 * @Author: your name
 * @Date: 2021-01-26 21:49:24
 * @LastEditTime: 2021-01-30 00:01:41
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: /portal/src/router/index.js
 */
import Vue from 'vue';
import VueRouter from 'vue-router';
const CourseStudy  = () => import('../views/Course/Study/index.vue');
const CourseCode  = () => import('../views/Course/Code/index.vue');
const CourseTransfer  = () => import('../views/Course/Transfer/index.vue');
Vue.use(VueRouter);

const routes = [
  {
    path: '/',
    name: 'Home',
    components: {
      course: CourseTransfer
    },
    meta: {
      menuIndex: '/'
    },
  },
  {
    path: '/courseStudy',
    name: 'courseStudy',
    components: {
      course: CourseStudy
    },
  },
  {
    path: '/courseCode',
    name: 'courseCode',
    components: {
      course: CourseCode
    },
  },
  {
    path: '/courseTransfer',
    name: 'courseTransfer',
    components: {
      course: CourseTransfer
    },
  }
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
});

export default router;
