import { axios } from '@/utils/request'

/**
 * login func
 * parameter: {
 *     username: '',
 *     password: '',
 *     remember_me: true,
 *     captcha: '12345'
 * }
 * @param parameter
 * @returns {*}
 */
export function login(parameter) {
    return axios({
        url: '/v1/login',
        method: 'post',
        data: parameter
    })
}

export function reg(parameter) { 
    return axios({
        url: '/v1/register',
        method: 'post',
        data: parameter
    })
}

export function sendCode(parameter) { 
    return axios({
        url: '/v1/sms',
        method: 'post',
        data: parameter
    })
}

export function getWorks(parameter) {
    return axios({
        url: '/teaching/user/v1/pub/works',
        method: 'get',
        params: parameter
    })
}

export function getCollects(parameter) {
    return axios({
        url: '/teaching/user/v1/collects',
        method: 'get',
        params: parameter
    })
}

export function getUserInfo(parameter) {
    return axios({
        url: '/teaching/user/v1/pub/mine',
        method: 'get',
        params: parameter
    })
}


export function forgetPwd(parameter) {
    return axios({
        url: '/v1/findpwd/check',
        method: 'post',
        data: parameter
    })
}

export function changeNickname(parameter) {
    return axios({
        url: '/teaching/user/editProfile',
        method: 'put',
        data: parameter
    })
}

export function autoLogin(param) {
    return axios({
        url:'/mfx/loginUser',
        method:'post',
        data:param
    })
}